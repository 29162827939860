import React, { useState, useMemo } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { IPosRequest, IPosResponse } from '../models/models'
import { GetFailurePosApi } from '../services/getFailurePosApi'
import Swal from 'sweetalert2'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import moment from 'moment'
import IPInput from '../../../../../components/IPInput'

interface IPosState {
  selectedIslemTipi: ISelectOption
  posIssuesData: IPosResponse[]
  companyListOptions: ISelectOption[]
  companyListOption: any
  dealerCode: string
}

function POSIssues(props: BaseComponentWithContextProps) {
  const [dealerCode, setDealerCode] = useState('')
  const [trackingNumberFilter, setTrackingNumberFilter] = useState('')
  const [filteredList, setFilteredList] = useState<IPosResponse[]>([])
  const [showFilterRow, setShowFilterRow] = useState(false)
  const [bankSelectOptions, setBankSelectOptions] = useState<ISelectOption[]>([])
  const [bankSelectOption, setBankSelectOption] = useState<any>()
  const [errorSelectOptions, setErrorSelectOptions] = useState<ISelectOption[]>([])
  const [errorSelectOption, setErrorSelectOption] = useState<any>()
  const [dataCounter, setDataCounter] = useState<number | undefined>(undefined)

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const getInitialState = (): IPosState => {
    return {
      selectedIslemTipi: { label: 'Banka Hata', value: '1' },
      posIssuesData: [],
      companyListOption: null,
      companyListOptions: [
        { label: 'Index', value: '1000' },
        { label: 'Netex', value: '2300' },
        { label: 'Despec', value: '1200' },
        { label: 'Datagate', value: '1100' },
      ],
      dealerCode: '',
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IPosState>(intialState)

  const IIslemTipiOption: ISelectOption[] = [
    { label: 'Banka Hata', value: '1' },
    // { label: 'Sipariş ve Makbuz Yok', value: '2' },
    // { label: 'Sipariş var ,Makbuz yok', value: '3' },
    // { label: 'Banka Hata ,Son işlem', value: '4' },
  ]

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  //#region Tablo Bilgileri, Filtre ve Excel
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Şirket',
        accessor: 'col1',
      },
      {
        Header: 'Sorun',
        accessor: 'col2',
      },
      {
        Header: 'İşlem',
        accessor: 'col3',
      },
      {
        Header: 'İşlem Anı',
        accessor: 'col4',
      },
      {
        Header: 'Müşteri',
        accessor: 'col5',
      },
      {
        Header: 'Banka No',
        accessor: 'col6',
      },
      {
        Header: 'Banka',
        accessor: 'col7',
      },
      {
        Header: 'Kart İsmi',
        accessor: 'col8',
      },
      {
        Header: 'Kart Bilgileri',
        accessor: 'col9',
      },
      {
        Header: 'Tutar',
        accessor: 'col10',
      },
      {
        Header: 'Taksit',
        accessor: 'col11',
      },
      {
        Header: 'Sipariş No Makbuz No',
        accessor: 'col12',
      },
      {
        Header: 'Ek Açıklamalar',
        accessor: 'col13',
      },
      {
        Header: '3D',
        accessor: 'col14',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      filteredList.map(item => {
        const dt = `${item.UserName}${item.DealaerCode ? '@' + item.DealaerCode.replace('0000', '') : ''} ; ${item.DealerName
          }`
        const card = `${item.CarNumber};${item.CardInfos}`
        const data = {
          col1:
            item.Company && item.Company === '1000'
              ? 'IDX'
              : item.Company === '2300'
                ? 'NTX'
                : item.Company === '1200'
                  ? 'DPC'
                  : item.Company === '1100'
                    ? 'DGT'
                    : '',
          col2: item.Fail,
          col3: item.ProcessType,
          col4: moment(item.ProcessTime).format('DD.MM.YYYY HH:mm:ss'),
          col5: dt,
          col6: item.BankNo,
          col7: item.Bank,
          col8: item.CardName.toLocaleUpperCase('tr-TR'),
          col9: card,
          col10: item.PayInfo,
          col11:
            item.PayInstallment && item.PayInstallment === '0'
              ? 'Tek Çekim'
              : item.PayInstallment === '1'
                ? 'Tek Çekim'
                : item.PayInstallment + ' Taksit',
          col12: item.OrderNumber ? item.OrderNumber : '',
          col13: item.FailMessage,
          col14: item.is3D ? 'Evet' : 'Hayır',
        }
        return data
      }),
    [filteredList]
  )

  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map((item, index) => {
            const dt = `${item.UserName}${item.DealaerCode ? '@' + item.DealaerCode.replace('0000', '') : ''} ; ${item.DealerName
              }`
            const card = `${item.CarNumber};${item.CardInfos}`

            return data.push({
              Şirket:
                item.Company && item.Company === '1000'
                  ? 'IDX'
                  : item.Company === '2300'
                    ? 'NTX'
                    : item.Company === '1200'
                      ? 'DPC'
                      : item.Company === '1100'
                        ? 'DGT'
                        : '',
              Sorun: item.Fail,
              İşlem: item.ProcessType,
              'İşlem Anı': moment(item.ProcessTime).format('DD.MM.YYYY HH:mm:ss'),
              Müşteri: dt,
              'Banka No': item.BankNo,
              Banka: item.Bank,
              'Kart İsmi': item.CardName.toLocaleUpperCase('tr-TR'),
              'Kart Bilgileri': card,
              Tutar: parseFloat(item.PayInfo.replaceAll('.', '').replace(',', '.')),
              Taksit:
                item.PayInstallment && item.PayInstallment === '0'
                  ? 'Tek Çekim'
                  : item.PayInstallment === '1'
                    ? 'Tek Çekim'
                    : item.PayInstallment + ' Taksit',
              'Sipariş/Makbuz No': item.OrderNumber ? item.OrderNumber : '',
              'Ek Açıklamalar': item.FailMessage,
              '3D': item.is3D ? 'Evet' : 'Hayır',
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Pos Sorunları Raporu')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const filterList = () => {
    const filterConditions: ((item: IPosResponse) => boolean)[] = []

    if (bankSelectOption && trackingNumberFilter.length < 10) {
      filterConditions.push(b => b.Bank.toUpperCase() === bankSelectOption.label)
    }

    if (errorSelectOption) {
      filterConditions.push(b => b.FailMessage === errorSelectOption.label)
    }

    if (dealerCode) {
      filterConditions.push(c => c.DealaerCode.replace('0000', '') === dealerCode)
    }

    if (trackingNumberFilter) {
      const validLengths = [10, 13, 14]

      if (validLengths.includes(trackingNumberFilter.length)) {
        setBankSelectOption(null)
        state.companyListOption = null
      }
      filterConditions.push(x => x.OrderNumber.includes(trackingNumberFilter.toUpperCase()))
    }

    if (state.companyListOption) {
      filterConditions.push(c => c.Company === state.companyListOption.value)
    }
    let filteredData: IPosResponse[] = state.posIssuesData

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setDataCounter(filteredData.length)
    setFilteredList(filteredData)
  }
  //#endregion

  const getPosIssues = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarih giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const uniqueBankLabels = {}
    const req: IPosRequest = {
      StartDate: startDate && (getDateByTimezoneOffset(startDate) as any),
      EndDate: endDate && (getDateByTimezoneOffset(endDate) as any),
      DealerCode: state.dealerCode.trim(),
    }
    props.showLoading()
    const getFailurePosApi: GetFailurePosApi = new GetFailurePosApi({})
    await getFailurePosApi
      .getFailurePos(req)
      .then(result => {
        if (result) {
          setBankSelectOptions(
            result
              .map(x => ({
                value: x.BankNo,
                label: x.Bank.toUpperCase(),
              }))
              .filter(option => {
                if (!uniqueBankLabels[option.label]) {
                  uniqueBankLabels[option.label] = true
                  return true
                }
                return false
              })
          )
          setErrorSelectOptions(
            result
              .map(x => ({
                value: x.FailMessage,
                label: x.FailMessage,
              }))
              .filter(option => {
                if (!uniqueBankLabels[option.label]) {
                  uniqueBankLabels[option.label] = true
                  return true
                }
                return false
              })
          )
          setState({ posIssuesData: result })
          setFilteredList(result)
          setDataCounter(result.length)
          setShowFilterRow(true)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  return (
    <div className=''>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>{t('pages.admin.CreditCardOperation.POSIssues.header')}</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row mb-3'>
                <div className='col-12 col-md-2 mb-3'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-2 mb-3'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='ProductAddress'
                    placeholder='Bayi Kodu'
                    onChange={e => setState({ dealerCode: String(e.target.value) })}
                    value={state.dealerCode && state.dealerCode}
                  />
                  <p style={{ color: 'red' }}>* Boş bırakırsanız hepsi</p>
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPSelectBox
                    isDisabled={true}
                    isClearable
                    options={IIslemTipiOption}
                    value={state.selectedIslemTipi}
                  // onChangeSingle={option => setState({ companiesOption: option })}
                  />
                </div>
                <div className='col-12 col-md-2 mb-3'>
                  <IPButton text='Göster' className='btn btn-danger' onClick={getPosIssues} />
                </div>
              </div>
            </form>
            {showFilterRow && (
              <>
                <div
                  className='detailFilter'
                  style={{
                    marginTop: '10px',
                    border: '2px solid #9c9cf1',
                    padding: '0px  5px',
                    paddingTop: '10px',
                  }}
                >
                  <div className='row ms-1 mt-3'>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        placeholder='Banka Seçiniz...'
                        options={bankSelectOptions}
                        value={bankSelectOption}
                        onChangeSingle={option => {
                          setBankSelectOption(option)
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        placeholder='Şirket Seçiniz...'
                        options={state.companyListOptions}
                        value={state.companyListOption}
                        onChangeSingle={option => {
                          setState({ companyListOption: option })
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        placeholder='Hata Seçiniz...'
                        options={errorSelectOptions}
                        value={errorSelectOption}
                        onChangeSingle={option => {
                          setErrorSelectOption(option)
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPInput
                        type='text'
                        id='Title'
                        className='form-control'
                        name='Title'
                        placeholder='Cari Kodu'
                        onChange={e => setDealerCode(e.target.value)}
                        value={dealerCode}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            filterList()
                          }
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPInput
                        type='text'
                        id='Title'
                        className='form-control'
                        name='Title'
                        placeholder='Makbuz No TKY...'
                        onChange={e => setTrackingNumberFilter(e.target.value)}
                        value={trackingNumberFilter}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            filterList()
                          }
                        }}
                      />
                    </div>
                    <div className='col-12 col-md-2 mb-2'>
                      <IPButton
                        type='button'
                        className='btn btn-primary'
                        text='Detaylı Süz'
                        onClick={filterList}
                        style={{ height: '45px' }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='m-4'>
              <Table
                columns={tableColumns}
                data={tableData}
                currentPage={0}
                headerName={`İşlem Tarihi Aralığı: ${new Date(startDate).toLocaleDateString()} - ${new Date(
                  endDate
                ).toLocaleDateString()} ---TOPLAM İŞLEM SAYISI: ${dataCounter}`}
                columnCount={14}
                backgroundColor='red'
                color='white'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(POSIssues)
