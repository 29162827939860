import { css } from '@emotion/css'
import { useState } from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Keyboard, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { translate } from '../../../base/localization'
import { splitStockForTruck, stockIndicator } from '../../../base/utils/dataHelper'
import AddToBasket from '../../../components/AddToBasket'
import Image from '../../../components/IPImage/IPImage'
import IPInput from '../../../components/IPInput'
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks'
import { addItemToBasketRedux } from '../../../redux/slices/basket/basketSlice'
import {
  addToFavoriteProductListRedux,
  productIsOnFavoriteListRedux,
  removeFromFavoriteProductListRedux,
} from '../../../redux/slices/favoriteProduct/favoriteProductSlice'
import { selectedTheme } from '../../../redux/slices/theme/themeSlice'
import Translations from '../../../translations'
import { IBestSellersCampaing, IBestSellersData } from '../models/commonModels'
import { IProduct } from '../models/response/getProductListResponseModel'
import Star from './Icons/Star'
import IPPriceDisplay from '../../../components/IPPriceDisplay'

export interface IProductCard1Props {
  forwardIQuotePage?(product: IProduct): void
  product: IProduct
  bestSellers?: IBestSellersData[]
  index?: number
  isSelected?: boolean
  onSelectProductForCompare?: () => void
  onAddListToBasketUI?: any
  handleTooltip?: (e: any, content: any) => void
  closeTooltip?: () => void
  isOpportunityMarket?: boolean
  isPromotionalProducts?: boolean
  isBestSellers?: boolean
  bestSellersCampaing?: IBestSellersCampaing[]
}

export default function ProductCard1(props: IProductCard1Props) {
  const t = translate(Translations)
  const [quantity, setQuantity] = useState(1)

  const theme = useAppSelector(selectedTheme)

  const dispatch = useAppDispatch()
  const isProductOnFavorites = useAppSelector(productIsOnFavoriteListRedux(props.product.Id))

  const handleQuantity = (e: any) => {
    props.onAddListToBasketUI &&
      props.onAddListToBasketUI(props.product, e.target.value, props.isOpportunityMarket, !props.isSelected)
    setQuantity(e.target.value)
  }

  const handleQuantityOnBlur = (e: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value))) {
      setQuantity(1)
      props.onAddListToBasketUI &&
        props.onAddListToBasketUI(props.product, 1, props.isOpportunityMarket, !props.isSelected)
    } else {
      props.onAddListToBasketUI &&
        props.onAddListToBasketUI(props.product, e.target.value, props.isOpportunityMarket, !props.isSelected)
      setQuantity(e.target.value)
    }
  }

  const animateIcon = (e: any) => {
    e.target.closest('svg').classList.add('clickAnimation')
    setTimeout(() => {
      e.target.closest('svg').classList.remove('clickAnimation')
    }, 300)
  }

  const handleStar = (e: any, productId: number) => {
    if (isProductOnFavorites) {
      removeFavorite(productId)
    } else {
      addFavorite(productId)
    }

    animateIcon(e)
  }

  const addFavorite = (productId: number) => {
    dispatch(addToFavoriteProductListRedux(productId))
  }

  const removeFavorite = (productId: number) => {
    dispatch(removeFromFavoriteProductListRedux(productId))
  }

  const navigate: NavigateFunction = useNavigate()
  const addToBasketOrCreateDemand = () => {
    dispatch(
      addItemToBasketRedux({
        ProductId: props.product.Id,
        Quantity: 1,
        IsOppurtunityStock: props.product.OpportunityId ? true : false,
        OpportunitiyStockId: props.product.OpportunityId ?? 0,
      })
    )

    Swal.fire({
      html: `<h5>${t('components.AddToBasket.productWasAddedToBasket')}</h5>`,
      confirmButtonText: `${t('components.AddToBasket.goToBasket')}`,
      confirmButtonColor: '#ec4c4c',
      cancelButtonText: `${t('components.AddToBasket.continueToShopping')}`,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate('/mycard')
      } else if (result.isDenied) {
      }
    })
  }

  const tooltipContent = (
    <div
      className={css`
        background-color: #ffff;
        color: #dc3545;
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
      `}
    >
      {props.product.ProductCampaigns &&
        props.product.ProductCampaigns.length > 0 &&
        props.product.ProductCampaigns.map(p => <div>{p.ProductListCode}</div>)}
    </div>
  )

  const campaignSVG = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='18' height='18' fill='#fff'>
      <path d='M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z' />
    </svg>
  )

  return (
    <>
      <div className='productCard' key={props.product.Id}>
        {props.isBestSellers && (
          <div className='text-center'>
            <b>
              {
                props.bestSellers?.find(camp => {
                  return camp.Index === props.index
                })?.CategoryName
              }
            </b>
          </div>
        )}
        {props.product.OnCampaign && (
          <div className='indicator topLeft'>
            <img src='/images/products/discount.svg' alt='' />
          </div>
        )}
        {props.isOpportunityMarket ? (
          <div className='indicator topLeft2'>
            <div className='hasTooltip' title='Fırsat Ürünü'>
              {/* <a onClick={() => addToBasketOrCreateDemand()}> */}
              <Image src='/images/products/box-percent.png' alt='' />
              {/* <div className="otooltip">
                Fırsat Stok: {props.product.Stock && props.product?.Stock?.Opportunity ? stockIndicator(props.product?.Stock?.Opportunity) : "0"}
              </div>
            </a> */}
            </div>
          </div>
        ) : (
          props.product?.Stock?.Opportunity > 0 && (
            <div className='indicator topLeft2'>
              <div className='hasTooltip'>
                <Link to={`/opportunit/detail/${props.product.OpportunityId}`}>
                  {/* <a onClick={() => addToBasketOrCreateDemand()}> */}
                  <Image src='/images/products/box-percent.png' alt='' />
                  <div className='otooltip'>
                    {/* {props.product.OpportunityPriceDisplay} */}
                    <IPPriceDisplay price={props.product.OpportunityPriceDisplay ?? ''} />
                    fiyatla FIRSAT PAZARI'ndan almak için tıklayınız...
                  </div>
                  {/* </a> */}
                </Link>
              </div>
            </div>
          )
          // <div className="indicator topLeft2">
          //   {props.product?.Stock?.OpportunityFlag > 0 ? (
          //     <div className="hasTooltip">
          //       <Image
          //         src="/images/phone-solid.svg"
          //         alt=""
          //         style={{ width: "15px" }}
          //       />
          //       <div className="otooltip">Fırsat Stoğu Rezerve</div>
          //     </div>
          //   ) : (
          //     props.product?.Stock?.Opportunity > 0 && (
          //       <div className="hasTooltip">
          //         <a onClick={() => addToBasketOrCreateDemand()}>
          //           <Image src="/images/products/box-percent.png" alt="" />
          //           <div className="otooltip">
          //             Fırsat Stok: {props.product.Stock && props.product?.Stock?.Opportunity ? stockIndicator(props.product?.Stock?.Opportunity) : "0"}
          //           </div>
          //         </a>
          //       </div>
          //     )
          //   )}
          // </div>
        )}

        {props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300' && (
          <div className='hpeWrapper'>
            <img src='/images/HPE_logo.png' alt='' />
            <div className='iQuote' onClick={() => props.forwardIQuotePage!(props.product)}>
              iQuote
            </div>
          </div>
        )}

        <input
          className='form-check-input check-box productCheckbox'
          type='checkbox'
          checked={props.isSelected}
          onChange={() => {
            props.onSelectProductForCompare && props.onSelectProductForCompare()
            props.onAddListToBasketUI &&
              props.onAddListToBasketUI(props.product, quantity, props.isOpportunityMarket, !props.isSelected)
          }}
          style={{ top: 80, left: 10, position: 'absolute' }}
        />

        {props.product.ProductCampaigns && props.product.ProductCampaigns.length > 0 && (
          <div
            className='indicator topRight'
            onMouseEnter={e => props.handleTooltip && props.handleTooltip(e, tooltipContent)}
            onMouseLeave={props.closeTooltip}
          >
            {campaignSVG}
          </div>
        )}

        {/* <div className="indicator topRight2">Yeni</div> */}

        <div className='productImage'>
          {props.product.Images && props.product.Images.length > 0 ? (
            <Swiper
              loop
              navigation={{
                nextEl: `#next-${props.product.Id}`,
                prevEl: `#prev-${props.product.Id}`,
              }}
              keyboard
              pagination={{ clickable: true }}
              modules={[Navigation, Keyboard, Pagination]}
            >
              {props.product.Images &&
                props.product.Images.map((banner: any, index: number) => {
                  return (
                    index < 11 && (
                      <SwiperSlide key={index}>
                        <Link
                          to={
                            props.isOpportunityMarket
                              ? `/opportunit/detail/${props.product.OpportunityId}?from=productList`
                              : `/product/detail/${props.product.Id}?from=productList`
                          }
                        >
                          <Image className='banner' src={banner.ImageUrl} alt='' />
                        </Link>
                      </SwiperSlide>
                    )
                  )
                })}
            </Swiper>
          ) : (
            <Link
              to={
                props.isOpportunityMarket
                  ? `/opportunit/detail/${props.product.OpportunityId}?from=productList`
                  : `/product/detail/${props.product.Id}?from=productList`
              }
            >
              <Image className='banner' src='./images/products/Resim_yok-01.jpg' alt='' />
            </Link>
          )}
          {props.product.Images.length > 1 && (
            <>
              <div id={`prev-${props.product.Id}`} className={`prev`}>
                <i className='icon-chevron-left'></i>
              </div>
              <div id={`next-${props.product.Id}`} className={`next`}>
                <i className='icon-chevron-right'></i>
              </div>
            </>
          )}
          {/* <Link to={`/product/${props.product.Id}`} id={props.product.Name}>
          <img src={props.product.Image} alt="" />
        </Link> */}
        </div>

        <div className='actions' style={{ top: '60px' }}>
          {/* <div className="action">
          <Heart
            color="#C3C3C4"
            hover="#E80000"
            isFavorite={props.isFavoriteProduct}
            click={handleFavorite}
          />
        </div> */}
          {!props.isOpportunityMarket && (
            <Star
              color='#C3C3C4'
              hover='red'
              isStarred={isProductOnFavorites}
              isFavoriteProduct={isProductOnFavorites}
              click={e => handleStar(e, props.product.Id)}
            />
          )}
          {props.product?.WarrantyMonth > 0 && (
            <div className='action' style={{ overflow: 'unset' }}>
              <div className='hasTooltip d-flex align-items-center justify-content-center p-1'>
                <img src='/images/products/quality2.png' alt='' />
                <div className='otooltip'>Garanti Süresi: {props.product?.WarrantyMonth} ay</div>
              </div>
            </div>
          )}

          <div className='action'>
            <img
              src={props.product?.Company?.Id === 2 ? '/images/netex_logo.png' : props.product?.Company?.Image}
              alt=''
            />
          </div>
          <div>
            {props?.product.Colors &&
              <div className='action' style={{ display: 'contents' }}>
                {props?.product.Colors?.map((item, outerIndex) => {
                  const col3Content = item.ProductIds.slice(0, 2).map((productId, innerIndex) =>
                    item.Type == 1 && (
                      <div
                        key={innerIndex}
                        style={{
                          position: "absolute",
                          left: `${innerIndex * 8}px`, // İkinci renk biraz sağa kayacak
                          zIndex: innerIndex, // Üst üste binen sıralama
                          backgroundColor: innerIndex === 0 ? item.ColorCodes[item.ProductIds.findIndex(a => a === props.product.Id)] :
                            item.ColorCodes[item.ProductIds.findIndex(a => a === props.product.Id) != 0 ? 0 : innerIndex],
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          border: item.ColorCodes[innerIndex] !== "white" ? "1px solid black" : "1px solid lightgray",
                        }}
                      />
                    )
                  )

                  return (
                    <div className="d-flex ms-2" key={outerIndex}>
                      <div
                        className="content-box"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "white",
                          borderRadius: "15px",
                          padding: "2px 6px",
                          position: "relative",
                          minWidth: "35px",
                        }}
                      >
                        <div style={{ position: "relative", width: "25px", height: "15px" }}>
                          {col3Content}
                        </div>
                        {item.ProductIds.length > 2 && (
                          <span
                            style={{
                              fontSize: "12px",
                              marginLeft: "1px",
                              color: "#333",
                            }}
                          >
                            +{item.ProductIds.length - 2}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            }
          </div>
        </div>

        <div className='productInfo2 text-left'>
          <Link
            to={
              props.isOpportunityMarket
                ? `/opportunit/detail/${props.product.OpportunityId}?from=productList`
                : `/product/detail/${props.product.Id}?from=productList`
            }
          >
            <div className='productName'>{props.product.Name}</div>
          </Link>
          {/* <div className='productModel'>Model: {props.product.Model}</div> */}
          <div className='productCode'>Ürün Kodu: {props.product.ProductCode}</div>
          {props.isOpportunityMarket && <div className='productCode'>Seri No: {props.product.OpportunitySeriNo}</div>}
        </div>

        <div className='productInfoBox'>
          {props.product.BrandDetail && props.product.BrandDetail.ImageUrl && (
            <div className='productBrand'>
              <img
                className='mx-auto d-block'
                style={{ objectFit: 'contain' }}
                src={props.product.BrandDetail.ImageUrl}
                alt=''
              />
            </div>
          )}
          {props.isOpportunityMarket ? (
            <div>
              <span className='priceTitle'>Fırsat Fiyatı:</span>
              <span className='price' style={{ color: theme.colors.price.special }}>
                {/* {props.product?.OpportunityPriceDisplay ?? ''} */}
                <IPPriceDisplay price={props.product?.OpportunityPriceDisplay ?? ''} />
              </span>
            </div>
          ) : (
            <div style={{ marginTop: '5px' }}>
              <span className='priceTitle'>Size Özel:</span>
              {props.isPromotionalProducts ? (
                <span>
                  <span className='price' style={{ color: theme.colors.price.special }}>
                    {/* {props.product?.CampaignPrice?.CampaignPriceDisplay ?? ''} */}
                    <IPPriceDisplay price={props.product?.CampaignPrice?.CampaignPriceDisplay ?? ''} />
                  </span>
                  <del className='price mr-1' style={{ color: theme.colors.price.normal }}>
                    {/* {props.product?.Prices?.Normal?.Price ?? ''} */}
                    <IPPriceDisplay price={props.product?.Prices?.Normal?.Price ?? ''} />
                  </del>
                </span>
              ) : props.product?.SapCampaignNo ? (
                props.product?.SapCampaignNo.length > 0 ? (
                  <span>
                    <span className='price' style={{ color: theme.colors.price.special }}>
                      {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                      <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
                    </span>
                    <del className='price mr-1' style={{ color: theme.colors.price.normal }}>
                      {/* {props.product?.Prices?.Normal?.Price ?? ''} */}
                      <IPPriceDisplay price={props.product?.Prices?.Normal?.Price ?? ''} />
                    </del>
                  </span>
                ) : (
                  <span className='price' style={{ color: theme.colors.price.special }}>
                    {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                    <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
                  </span>
                )
              ) : (
                <span className='price' style={{ color: theme.colors.price.special }}>
                  {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                  <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
                </span>
              )}
            </div>
          )}
          {props.isOpportunityMarket ? (
            <div>
              <span className='priceTitle--gray'>Bayi Fiyatı:</span>
              <del className='price mr-1' style={{ color: theme.colors.price.normal }}>
                {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
              </del>
            </div>
          ) : (
            <div>
              <span className='priceTitle--gray'>Liste Fiyatı:</span>
              <span
                className={`price ${true ? 'price-none-decoration' : ''}`}
                style={{ color: theme.colors.price.normal }}
              >
                {/* {props.product?.Prices?.Normal?.Price ?? ''} */}
                <IPPriceDisplay price={props.product?.Prices?.Normal?.Price ?? ''} />
              </span>
            </div>
          )}

          {!props.isOpportunityMarket && (
            <div>
              <span className='priceTitle--gray'>TESK: </span>
              <span className='price' style={{ color: theme.colors.price.enduser }}>
                {/* {props.product?.Prices?.EndUser?.Price ?? ''} */}
                <IPPriceDisplay price={props.product?.Prices?.EndUser?.Price ?? ''} />
              </span>
            </div>
          )}

          <div className='d-flex align-items-center justify-content-between'>
            <div className='stock m-t-5'>
              Stok:{' '}
              {props.isOpportunityMarket
                ? props.product.OpportunityStock
                  ? stockIndicator(props.product.OpportunityStock)
                  : '0'
                : props.product.Stock && props.product.Stock.Stock
                  ? stockIndicator(props.product.Stock.Stock)
                  : '0'}
            </div>
            {props.product.StockForTruck && splitStockForTruck(props.product.StockForTruck).length > 0 && (
              <div className='hasTooltip'>
                <img src='/images/products/truck.svg' alt='' />
                <div className='otooltip'>
                  {splitStockForTruck(props.product.StockForTruck).map(st => {
                    return 'tarih : ' + st.date + ' stok : ' + st.stok
                  })}
                </div>
              </div>
            )}

            <div>
              Adet:{' '}
              <IPInput
                type='number'
                className='stockNumber myCardStockNumber'
                min={1}
                controlledField
                value={quantity}
                onChange={e => handleQuantity(e)}
                onBlur={e => handleQuantityOnBlur(e)}
              />
            </div>
          </div>
          {props.product.ProductCampaigns &&
            props.product.ProductCampaigns.length > 0 &&
            props.product.ProductCampaigns[0].ProductListCode && (
              <div className='position-relative text-danger text-center'>
                <div>{props.product.ProductCampaigns[0].ProductListCode}</div>
                {props.product.ProductCampaigns.length > 1 && (
                  <div
                    onMouseEnter={e => props.handleTooltip && props.handleTooltip(e, tooltipContent)}
                    onMouseLeave={props.closeTooltip}
                  >
                    Diğer Kampanyalar
                  </div>
                )}
              </div>
            )}
          {props.isOpportunityMarket &&
            props.product.OpportunityShortDescription &&
            props.product.OpportunityShortDescription.length > 0 && (
              <div className='position-relative text-danger text-center'>
                {props.product.OpportunityShortDescription}
              </div>
            )}
        </div>

        <div className='productButton'>
          <AddToBasket
            product={props.product}
            quantity={quantity}
            isOpportunityMarket={props.isOpportunityMarket}
            disabled={props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300'}
          />
        </div>
      </div>
    </>
  )
}
