import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import CustomCard from './Components/CustomCard'
import Image from '../../components/IPImage/IPImage'
import Translations from '../../translations'
import { translate } from '../../base/localization'
import IPAccordion from '../../components/IPAccordion/IPAccordionNoScroll'
import { getUploadFileRequest } from '../../base/utils/dataHelper'
import Swal from 'sweetalert2'
import { AddLogoApi } from './services/addLogoApi'
import { useAppSelector } from '../../redux/app/hooks'
import { useAppDispatch } from '../../redux/app/hooks'
import { currentUser } from '../../redux/slices/user/userSlice'
import { IException } from '../../base/models'
import _ from 'lodash'
import './Sidebar.scss'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'

function Slider(props: BaseComponentWithContextProps) {
  const user = useAppSelector(currentUser)
  const dispatch = useAppDispatch()
  const t = translate(Translations)
  const inputEl: any = useRef(null)

  const handleBannerImageUpload = async (event: any) => {
    const fileList = await getUploadFileRequest(event)

    if (fileList && fileList.length > 0) {
      addImage(fileList[0])
    }
  }

  const addImage = async (file: any) => {
    const addLogoApi: AddLogoApi = new AddLogoApi({})
    addLogoApi
      .addLogo({ AccountCode: user.AccountCode, File: file })
      .then((res: string) => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Logo değişimi yönetici tarafından onay verildikten sonra yapılacaktır.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata meydana geldi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  return (
    <React.Fragment>
      <div className='MySpecial'>
        <div className='row'>
          <div className='sidebar px-lg-2'>
            <CustomCard>
              <div className='imgMySpecial'>
                <NavLink to='/'>
                  <Image
                    className='Carilogo'
                    src={
                      user.DealerLogo
                        ? user.DealerLogo === 'https://index-content.vbt.com.tr:1443/'
                          ? '/images/notImage.png'
                          : user.DealerLogo
                        : '/images/notImage.png'
                    }
                    alt='Cari Logo'
                  />
                </NavLink>
                <p className='fa-camera-icon'>
                  <i
                    className='fa fa-camera'
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      padding: '5px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                    }}
                    onClick={() => inputEl.current.click()}
                  ></i>

                  <input
                    ref={inputEl}
                    type='file'
                    name='Image'
                    className='form-control'
                    id='customFile'
                    style={{ display: 'none' }}
                    onChange={event => {
                      handleBannerImageUpload(event)
                    }}
                  />
                </p>
              </div>

              <div className='line mt-0 mb-4'></div>

              <div className='sidebarImg'>
                {
                  <>
                    <img
                      src='/images/myspecial-icons/hesapyönetimi.svg'
                      alt=''
                      width={20}
                      className='mt-2'
                      style={{ marginRight: '15px' }}
                    />
                    <IPAccordion name='accountInfo' labelName={t('pages.mySpecial.slider.accountInfo')}>
                      <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                        {!props.user?.IsRemote && (
                          <>
                            <NavLink to='/myspecial/InformationUpdate'>
                              <img src='/images/user-update.svg' alt='' width={18} />
                              <li>{t('pages.mySpecial.slider.informationUpdate')}</li>
                            </NavLink>
                            <NavLink to='/myspecial/Password'>
                              <img src='/images/change-password.svg' alt='' width={18} />
                              <li>{t('pages.mySpecial.slider.changePassword')}</li>
                            </NavLink>
                          </>
                        )}
                        <NavLink to='/myspecial/AddNewUser'>
                          <img src='/images/add-new-user.svg' alt='' width={18} />
                          <li>{t('pages.mySpecial.slider.addNewUser')}</li>
                        </NavLink>
                        <NavLink to='/myspecial/UserUpdate'>
                          <img src='/images/user-update.svg' alt='' width={18} />
                          <li>{t('pages.mySpecial.slider.userUpdate')}</li>
                        </NavLink>
                      </ul>
                    </IPAccordion>
                  </>
                }
                <div className='sidebarText'>
                  <NavLink to='/myspecial'>
                    <img src='/images/myspecial-icons/dashboard-22.svg' alt='' width={25} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '5px' }}>Dashboard</span>
                  </NavLink>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/myfavorites'>
                    <img src='/images/myspecial-icons/favorilerim.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.myFavorites')}
                    </span>
                  </NavLink>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/Address'>
                    <img src='/images/myspecial-icons/adreslerim.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.myAddresses')}
                    </span>
                  </NavLink>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/MyCreditCards'>
                    <img src='/images/credi-card.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      Kredi Kartlarım
                    </span>
                  </NavLink>
                </div>
                {(props.user.IsPermission || props.user.IsMonitorSalesAndOrder) && (
                  <div className='sidebarText'>
                    <NavLink to='/myspecial/myorders'>
                      <img src='/images/myspecial-icons/siparişlerim.svg' alt='' width={20} />
                      <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                        {t('pages.mySpecial.slider.myOrders')}
                      </span>
                    </NavLink>
                  </div>
                )}
                {/* <div className='sidebarText'>
                  <NavLink to='/myspecial/myorders'>
                    <img src='/images/myspecial-icons/yurtdışısipariş.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: "10px" }}>
                      {t('pages.mySpecial.slider.overseasOrderTracking')}
                    </span>
                  </NavLink>
                </div> */}
                <div className='sidebarText'>
                  <NavLink to='/myspecial/myReturns'>
                    <img src='/images/myspecial-icons/iadelerim.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.myReturns')}
                    </span>
                  </NavLink>
                </div>
                {/* <div className='sidebarText'>
                  <NavLink to='/financial-information/bank-account-numbers'>
                    <img src='/images/myspecial-icons/finans.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: "10px" }}>
                      Finansal Bilgiler
                    </span>
                  </NavLink>
                </div> */}
                {(props.user.IsPermission || props.user.IsMakeFinancialTransactions) && (
                  <div className='sidebarText'>
                    <NavLink to='/myspecial/mypayments'>
                      <img src='/images/myspecial-icons/ödemelerim.svg' alt='' width={20} />
                      <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                        {t('pages.mySpecial.slider.myPayments')}
                      </span>
                    </NavLink>
                  </div>
                )}
                {(props.user.IsPermission || props.user.IsMonitorFinancialAndCurrentMovements) && (
                  <>
                    <img
                      src='/images/myspecial-icons/carihesabım.svg'
                      alt=''
                      width={20}
                      className='mt-1'
                      style={{ marginRight: '15px' }}
                    />
                    <IPAccordion name='myCurrentAccount' labelName={t('pages.mySpecial.slider.myCurrentAccount')}>
                      <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                        {/* <NavLink to='/myspecial/currentAccount/consensus/list'>
                          <li>{t('pages.mySpecial.slider.currentAccountReconciliation')}</li>
                        </NavLink> */}
                        <NavLink to='/myspecial/currentAccount/extract'>
                          <li>{t('pages.mySpecial.slider.currentAccountStatement')}</li>
                        </NavLink>
                        <NavLink to='/myspecial/currentAccount/letterGuarantee'>
                          <li>{t('pages.mySpecial.slider.letterOfGuarantee')}</li>
                        </NavLink>
                      </ul>
                    </IPAccordion>
                  </>
                )}
                <div className='sidebarText'>
                  <NavLink to='/myspecial/creditCardOperations'>
                    <img src='/images/myspecial-icons/kredikartıahreket.svg' alt='' width={20} />
                    <span
                      style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px', position: 'absolute' }}
                    >
                      {t('pages.mySpecial.slider.myCreditCardTransactions')}
                    </span>
                  </NavLink>
                </div>
                <br></br>
                <>
                  <img
                    src='/images/myspecial-icons/Faturalarım.svg'
                    alt=''
                    width={20}
                    className='mt-1'
                    style={{ marginRight: '15px' }}
                  />
                  <IPAccordion name='myCurrentAccount' labelName={'Faturalarım'}>
                    <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                      <NavLink to='/myspecial/reports/open-invoices'>
                        <li>{t('pages.mySpecial.slider.openInvoices')}</li>
                      </NavLink>
                      <NavLink to='/myspecial/reports/e-invoice'>
                        <li>{t('pages.mySpecial.slider.electronicInvoices')}</li>
                      </NavLink>
                      <NavLink to='/myspecial/reports/podocument'>
                        <li>Fiyat Farkı Faturalarım</li>
                      </NavLink>
                    </ul>
                  </IPAccordion>
                </>
                <>
                  <img
                    src='/images/myspecial-icons/SeriNoSorgu.svg'
                    alt=''
                    width={20}
                    className='mt-1'
                    style={{ marginRight: '15px' }}
                  />
                  <IPAccordion name='myCurrentAccount' labelName={'Seri No / Imei No Kontrol '}>
                    <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                      <NavLink to='/myspecial/ssh/SerialNumberCheck'>
                        <li>{t('pages.mySpecial.slider.serialNumberCheck')}</li>
                      </NavLink>
                      <NavLink to='/myspecial/reports/imei-serial-number'>
                        <li>{t('pages.mySpecial.slider.imeiSerialNumber')}</li>
                      </NavLink>
                    </ul>
                  </IPAccordion>
                </>
                <div className='sidebarText'>
                  <img
                    src='/images/myspecial-icons/lisanstakip.svg'
                    alt=''
                    width={20}
                    style={{ marginRight: '15px', paddingTop: '5px' }}
                  />
                  <IPAccordion name='accountInfo' labelName='Microsoft ESD Takip'>
                    <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                      <NavLink to='/myspecial/licenseTracking/microsoft'>
                        <li>Lisans Takip – Yenileme</li>
                      </NavLink>
                      <NavLink to='/myspecial/licenseTracking/subscriptionTracking'>
                        <li>Abonelik Takip</li>
                      </NavLink>
                    </ul>
                  </IPAccordion>
                </div>
                <img
                  src='/images/myspecial-icons/raporlarım.svg'
                  alt=''
                  width={20}
                  style={{ marginRight: '15px', paddingTop: '5px' }}
                />
                <div className='m-t-3'>
                  {' '}
                  <IPAccordion name='myReports' labelName={t('pages.mySpecial.slider.myReports')}>
                    <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                      {/* <NavLink to='/myspecial/reports/account-summary'>
                        <li>{t('pages.mySpecial.slider.accountSummaryTable')}</li>
                      </NavLink> */}
                      <NavLink to='/myspecial/reports/periodic-report'>
                        <li>{t('pages.mySpecial.slider.periodicAndDetailedTurnoverReport')}</li>
                      </NavLink>
                      {/* <NavLink to='/myspecial/reports/credit-card-transactions'>
                        <li>{t('pages.mySpecial.slider.myCreditCardTransactions')}</li>
                      </NavLink> */}
                      <NavLink to='/myspecial/reports/credit-statement'>
                        <li>Kredi Başvuru/Limit Artırımı</li>
                      </NavLink>
                      <NavLink to='/myspecial/reports/provizyon'>
                        <li>Provizyon Hakediş</li>
                      </NavLink>
                    </ul>
                  </IPAccordion>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/DealerChannelPrograms'>
                    <img src='/images/myspecial-icons/bayikanal.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.dealerChannelPrograms')}
                    </span>
                  </NavLink>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/xmlSupport'>
                    <img src='/images/myspecial-icons/xmldestek.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.xmlSupport')}
                    </span>
                  </NavLink>
                </div>

                <div className='sidebarText'>
                  <NavLink to='/myspecial/webinarCalendar'>
                    <img src='/images/myspecial-icons/takvim-14.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.webinarCalendar')}
                    </span>
                  </NavLink>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/dealerSpecificPromotional'>
                    <img src='/images/myspecial-icons/bayiyeözelpromosyon.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px', display: 'flex' }}>
                      {t('pages.mySpecial.slider.dealerSpecificPromotionalCampaigns')}
                    </span>
                  </NavLink>
                </div>
                <div className='sidebarText'>
                  <NavLink to='/myspecial/myOffers'>
                    <img src='/images/myspecial-icons/tekliflerim.svg' alt='' width={20} />
                    <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px' }}>
                      {t('pages.mySpecial.slider.myOffers')}
                    </span>
                  </NavLink>
                </div>
                <img
                  src='/images/myspecial-icons/satışsonrası.svg'
                  alt=''
                  width={20}
                  className='pt-1'
                  style={{ marginRight: '15px' }}
                />
                <IPAccordion name='ssh' labelName={t('pages.mySpecial.slider.ssh')}>
                  <ul className='list-inline mb-0' style={{ fontSize: '14px', color: 'rgb(97, 97, 97)' }}>
                    <NavLink to='/myspecial/ssh/WarrantyTracking'>
                      <li>{t('pages.mySpecial.slider.warrantyTracking')}</li>
                    </NavLink>
                    <NavLink to='/myspecial/ssh/AuthorizedServicePoints'>
                      <li>{t('pages.mySpecial.slider.authorizedServicePoints')}</li>
                    </NavLink>
                    <NavLink to='/myspecial/ssh/FaultTracking'>
                      <li>{t('pages.mySpecial.slider.faultTracking')}</li>
                    </NavLink>
                    <NavLink to='/myspecial/ssh/SSHProceduresBrandList'>
                      <li>{t('pages.mySpecial.slider.sshProcedures')}</li>
                    </NavLink>
                    <NavLink to='/myspecial/ssh/SSHContactForm'>
                      <li>{t('pages.mySpecial.slider.sshContactForm')}</li>
                    </NavLink>
                    <NavLink to='/myspecial/ssh/SSS'>
                      <li>{t('pages.mySpecial.slider.sss')}</li>
                    </NavLink>
                  </ul>
                </IPAccordion>
              </div>
              <div className='sidebarText'>
                <NavLink to='/myspecial/ssh/AuthorizedServicePoints'>
                  <img src='/images/myspecial-icons/yetkilihizmet-01.svg' alt='' width={20} />
                  <span style={{ fontSize: '17px', color: 'rgb(97, 97, 97)', paddingLeft: '10px', fontWeight: 500 }}>
                    {t('pages.mySpecial.slider.authorizedServicePoints')}
                  </span>
                </NavLink>
              </div>
              <div className='seperator my-1'></div>
              <div>
                <h6 style={{ color: 'red' }}>İLETİŞİM</h6>
              </div>
              {/* <div className='d-flex align-items-center '>
                <img src='/images/phone.svg' alt='' width={20} className='m-r-10' />
                <div className='mt-2'>
                  <span>{t('pages.mySpecial.slider.phone')}</span>
                </div>
              </div>
              <div style={{ fontSize: '1.2rem' }} className='sidebarBottom'>
                <a href='tel:0212 3312251'> 0212 3312251 </a>
                <br />
                <a href='tel:0212 3312256'> 0212 3312256 </a>
                <br />
                <a href='tel: 0212 3312257'> 0212 3312257 </a>
              </div> */}
              <div className='d-flex align-items-center mb-2 '>
                <img src='/images/letter.svg' alt='' width={20} className='m-r-10' />
                <div className='mt-2'>
                  <span>E-Posta </span>
                </div>
              </div>
              <div style={{ fontSize: '1.2rem' }} className='sidebarBottom'>
                <a href='mailto:webcenter@index.com.tr' className='email-link'>
                  WebCenter@index.com.tr
                </a>
                <a href='mailto:dg-satis@datagate.com.tr' className='email-link'>
                  dg-satis@datagate.com.tr
                </a>
                <a href='mailto:despec-satis@despec.com.tr' className='email-link'>
                  dpc-bilgi@despec.com.tr
                </a>
                <a href='mailto:neteks-satis@neteks.com.tr' className='email-link'>
                  neteks-satis@neteks.com.tr
                </a>
              </div>
              <div>
                <p className='text-danger'>
                  <span className='font-weight-bold'>*</span>Siparişlerinizle ilgili sorularınızı lütfen ilgili şirket
                  temsilciniz ile görüşünüz.
                </p>
              </div>
            </CustomCard>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default componentWithContext(Slider)
