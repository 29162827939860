import { useEffect, useState } from 'react'
import Form from './Form'
import Slider from './Slider'
import Image from '../../components/IPImage/IPImage'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { isLoggedIn } from '../../base/proxy/authenticate'
import { translate } from '../../base/localization'
import Translations from '../../translations'
import SellerApplication from './components/SellerApplication'
import './styles.scss'

const data = [
  {
    id: 1,
    src: '/images/index.png',
    alt: 'Index Logo',
  },
  {
    id: 2,
    src: '/images/datagate.png',
    alt: 'Datagate Logo',
  },
  {
    id: 3,
    src: '/images/despec.png',
    alt: 'Despec Logo',
  },
  {
    id: 4,
    src: '/images/netex.png',
    alt: 'Netex Logo',
  },
]

export default function Register() {
  const navigate = useNavigate()
  const location: any = useLocation()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const trackingNumber = location.state?.trackingCode || code || null

  const t = translate(Translations)

  const [showRegisterPage, setShowRegisterPage] = useState(false)

  useEffect(() => {
    isLoggedInLocal()
  }, [])

  const isLoggedInLocal = () => {
    if (isLoggedIn()) {
      navigate('/homepage', {
        replace: true,
        state: { from: location.pathname },
      })
    } else {
      setShowRegisterPage(true)
    }
  }

  const requestedDocuments = (
    <div className='row mb-4'>
      <div className='col-12 mb-4' >
        <h5 style={{ padding: '5px 20px 0px 0px' }}>{t('pages.register.bayiForms.requestedDocuments')}</h5>
      </div>
      <div className='col-12 col-lg-6' style={{ paddingLeft: '0' }}>
        <div className='text-light mb-4' style={{ backgroundColor: '#ef3038', color: 'white' }}>
          <h5 style={{ padding: '5px 20px 0 20px', textAlign: 'center' }}>
            {t('pages.register.bayiForms.soleProprietorships')}
          </h5>
        </div>
        <ul>
          {/* <li>
							<Link to='/assetWeb/docs/E-Bayisozlesme.pdf' target='_blank'>
								{t('pages.register.bayiForms.dealerAgreement')}
							</Link>
						</li> */}
          <li>
            <span>{t('pages.register.bayiForms.signatureCircular')}</span>
          </li>
          <li>
            <span>{t('pages.register.bayiForms.taxBoard')}</span>
          </li>
          <li>
            <span>{t('pages.register.bayiForms.identityCard')}</span>
          </li>
          <li>
            <span>{t('pages.register.bayiForms.attendanceReceipt')}</span>
          </li>
        </ul>
        <label htmlFor='location' className='form-label'>
          <label htmlFor='location' className='form-label' style={{ color: 'red' }}>
            {'* Yetkili kişi ile evrakların uyuşması gerekmektedir.'}
          </label>
        </label>
      </div>
      <div className='col-12 col-lg-6' style={{ paddingRight: '0' }}>
        <div className='text-light mb-4' style={{ backgroundColor: '#ef3038', color: 'white' }}>
          <h5 style={{ padding: '5px 20px 0 20px', textAlign: 'center' }}>{t('pages.register.bayiForms.companies')}</h5>
        </div>
        <ul>
          {/* <li>
							<a href='/assetWeb/docs/E-Bayisozlesme.pdf' target='_blank'>
								{t('pages.register.bayiForms.dealerAgreement')}
							</a>
						</li> */}
          <li>
            <span>{t('pages.register.bayiForms.signatureCircular')}</span>
          </li>
          <li>
            <span>{t('pages.register.bayiForms.taxBoard')}</span>
          </li>
          <li>
            <span>{t('pages.register.bayiForms.identityCard')}</span>
          </li>
          <li>
            <span>{t('pages.register.bayiForms.registryGazette')}</span>
          </li>
        </ul>
        <label htmlFor='location' className='form-label'>
          <label htmlFor='location' className='form-label' style={{ color: 'red' }}>
            {'* Yetkili kişi ile evrakların uyuşması gerekmektedir.'}
          </label>
        </label>
      </div>
    </div>
  )

  return (
    <div>
      {showRegisterPage && (
        <div className='container bayiForm'>
          <div className='row'>
            <div className='col-lg-12 py-3'>
              <div className='row mb-4'>
                <div className='col-lg-8 px-0'>
                  <Link to='/login'>
                    <Image
                      className='form_logo'
                      alt='Index Pazar Logo'
                      src='/images/indexPazarLogo.png'
                      style={{ width: '250px' }}
                    />
                  </Link>
                </div>
                <div className='col-lg-4'>
                  <div className='row company-logos'>
                    {data.map(img => (
                      <div className='col-lg-3' key={img.id}>
                        <a className='btn btn-transparent'>
                          {' '}
                          <Image className='logo' alt={img.alt} src={img.src} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {trackingNumber && (
                <div className='row mb-4'>
                  <div className='col-lg-12 text-light' style={{ backgroundColor: '#ef3038', color: 'white' }}>
                    <h5 style={{ padding: '5px 20px 0 20px' }}>Online Bayi Başvuru Durumunuz</h5>
                  </div>
                </div>
              )}

              {!trackingNumber && (
                <>
                  <div className='row mb-4'>
                    <div className='col-lg-12 text-light' style={{ backgroundColor: '#ef3038', color: 'white' }}>
                      <h5 style={{ padding: '5px 20px 0 20px' }}>{t('pages.register.bayiForms.header')}</h5>
                    </div>

                    <div className='col-12'>
                      <h6 style={{ fontWeight: 600 }}> {t('pages.register.bayiForms.subHeader')}</h6>
                      <ul className='p-l-15 p-r-15'>
                        <li>{t('pages.register.bayiForms.description1')}</li>
                        <li>
                          Index Grup şirketleri bayiliği ve e-bayiliği için firma evraklarınızda bilgisayar alım satımı
                          yapıldığına dair ibare bulunmalıdır. Geçerli olan faaliyet alanları için{' '}
                          <a target={'_blank'} href='/assetWeb/docs/NACE_KODLARI.xls'>
                            {' '}
                            tıklayınız...
                          </a>
                        </li>
                        <li>{t('pages.register.bayiForms.description3')}</li>
                        <li>{t('pages.register.bayiForms.description4')}</li>
                        <li>{t('pages.register.bayiForms.description5')}</li>
                      </ul>
                      <p style={{ fontSize: '14px', color: 'red' }}>
                        * E-bayilik sözleşmenizi, tarafımıza kargo ile göndermenize <b>gerek yoktur.</b> Tüm
                        süreçlerimiz <b>online</b> olarak yürütülmektedir.
                      </p>
                    </div>
                  </div>

                  {requestedDocuments}
                </>
              )}

              <SellerApplication
                trackingNumber={trackingNumber && trackingNumber}
                requestedDocuments={requestedDocuments}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
