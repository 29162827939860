import { Link } from "react-router-dom";
import {
  BaseComponentWithContextProps,
  componentWithContext,
} from "../../base/customHooks/componentHOC";
import { logoutUser } from "../../base/proxy/authenticate";
import IPImage from "../../components/IPImage";

function NotFound(props: BaseComponentWithContextProps) {
  const logout = async () => {
    try {
      props.showLoading();
      await logoutUser();
      props.hideLoading();
    } catch {
      props.hideLoading();
    }
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <IPImage
          src={"images/notfounderror.jpg"}
          alt={""}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Resmi tam olarak doldurmak için
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Arka plan beyaz ve hafif şeffaf
            padding: "10px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <IPImage
              src={"images/home.png"}
              alt={""}
              style={{
                width: "24px",
                height: "24px", // Küçük ikon boyutu
                objectFit: "contain", // İkonun doğru oranda görünmesi
              }}
            />
            <b>
              Anasayfaya dönmek için{" "}
              <Link style={{ color: "blue" }} to={(props?.user?.IsApple) ? "/AppleNetChannel" : "/homepage"}>
                <span>tıklayınız</span>
              </Link>.
            </b>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <IPImage
              src={"images/cikis.png"}
              alt={""}
              style={{
                width: "24px",
                height: "24px", // Küçük ikon boyutu
                objectFit: "contain", // İkonun doğru oranda görünmesi
              }}
            />
            <b>
              Çıkış yapmak için{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => logout()}
              >
                tıklayınız
              </span>.
            </b>
          </div>
        </div>
      </div>
    </>
  );
}

export default componentWithContext(NotFound);
